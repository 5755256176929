.admin-invoicing-fees {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px;
  color: white;
}

.label-plus-date-invoice {
  display: flex;
  gap: 10px;
}
.fee-type-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.radio-input-group {
  display: flex;
  gap: 10px;
}

.interest-fields {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.balance-period-section {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.invoice-fees-button-section {
  display: flex;
  gap: 10px;
}
