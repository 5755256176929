.accounts-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 20px;
  color: white;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
}

.accounts-container p {
  margin: 0;
}

.accounts-left-column {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.accounts-right-column {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.accounts-images {
  width: 20px;
  height: 20px;
  background-color: #a7b045;
  padding: 8px;
  border-radius: 10px;
}

.accounts-tottal-calculation {
  display: flex;
  justify-content: space-between;
}

.account-ledger-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
