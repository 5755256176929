.checkbox-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.custom-form .k-multiselect .k-input-inner {
  width: 10em !important;
}
.custom-form .checkbox-group-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.checkbox-group-item {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.numeric-textbox {
  width: 70px !important;
}

.tab-content .k-input-md .k-spinner-decrease,
.tab-content .k-input-md .k-spinner-increase,
.form-group .k-icon-button.icon-button,
.numeric .k-input-md .k-spinner-decrease,
.numeric .k-input-md .k-spinner-increase {
  background-color: transparent;
  color: #939ea2;
}
.numeric .k-icon-button.icon-button {
  background-color: #919da3;
  color: #fff;
}
.numeric input,
.numeric .k-picker-solid,
.numeric .k-input-solid {
  border: 0px;
}

.numeric-textbox-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 90px;
}
.numeric,
.numeric-textbox-container {
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  opacity: 0.4;
  overflow: hidden;
  /* width: 90px; */
}
.numeric-textbox-container .k-input {
  border: 0px;
}

.custom-form .checkbox-class .ltr {
  width: max-content;
}
