.online-scheduling-container {
  padding: 20px;
  color: white;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.12px;
  background: #1a262f;
  padding: 20px;
  height: 100%;
}

.lable-plus-dropdown-online-scheduling {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
  gap: 20px;
}

.online-scheduling-container .k-input-solid {
  width: 300px !important;
}

.patient-type-plus-help {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.patient-buttons-online-scheduling {
  display: flex;
  gap: 20px;
  width: 300px;
}

.lable-plus-available-time-slot {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  gap: 20px;
  width: 500px;
}

.doctors-plus-available-slots {
  width: 60%;
}
