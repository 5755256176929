/* Make sure the body and html have full height */
html, body {
    height: 100%;
    margin: 0;
  }
  
  .layout-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Full viewport height */
  }
  
  .header {
    background-color: #B2B5E0 !important; /* Light Steel Blue */
  }
  
  .footer {
    background-color: #B2B5E0 !important; /* Light Steel Blue */
    padding: 1rem;
    text-align: center;
  }
  