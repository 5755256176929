.case-presenter {
  color: slategray;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.case-presenter-icon-button {
  background: #33a1e3 !important;
  color: white !important;
  padding: 4px 8px 4px 8px !important;
}

.case-presenter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.case-presenter-header-actions {
  display: flex;
  gap: 20px;
  align-items: center;
}

.case-actions {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
}

.case-actions .k-button {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-weight: bold;
}

.case-actions-plus-pie-chart {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
