.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent overflow on the wrapper */
}

.sidebar {
  width: 80px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.patient-page-main-content {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 110px);
  overflow-x: hidden;
  background: white;
  margin: 10px;
  padding: 15px;
  border-radius: 15px;
}

.tab-buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.patient-tab-buttons {
  display: flex;
  gap: 10px;
}

.patient-tab-buttons .k-button-solid-base {
  background: #ff6358 !important;
  color: white;
}

.patient-tab-content {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 85%;
}

.patient-page-main-content .k-tabstrip-content {
  display: none !important;
}

.patient-page-main-content .k-link {
  font-size: 16px;
  line-height: 19.12px;
  text-align: left;
}

.tab-buttons-container .k-tabstrip-items-wrapper .k-item.k-active {
  border-radius: 15px !important;
  border: 1px solid !important;
  background: rgba(0, 206, 144, 1) !important;
}
