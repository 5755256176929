.container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px;
  font-family: Arial, sans-serif;
}

.section {
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 300px;
  max-width: 400px;
}

.section-header p {
  position: absolute;
  top: -25px;
  left: 20px;
  padding: 0 10px;
  border-radius: 10px;
}

.section-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

.table {
  display: flex;
  flex-direction: column;
}

.table-header,
.table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #3a4a5a;
}

.table-row span {
  flex: 1;
}

.patients-visits-input-group {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.patients-visits-input-group label {
  width: 50%;
}

.patients-visits-input-group .k-numerictextbox {
  width: 50%;
}

.switches {
  display: flex;
  gap: 10px;
  margin: 20px 0;
}

.switch-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.patients-visits-save-button {
  background-color: #ff6358 !important;
  color: #ffffff !important;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
}

.production-goals-grid .k-button-solid-base {
  color: rgba(0, 206, 144, 1);
  background-color: transparent;
}

.departments-grid .k-button-solid-base {
  color: rgba(0, 206, 144, 1);
  background-color: transparent;
}
