.settings-profile-container {
  display: flex;
  gap: 4rem;
  margin: 20px;
  justify-content: space-around;
}

.settings-profile-label-plus-switch {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.settings-profile-container .k-switch-on .k-switch-track {
  background-color: #ff6358;
  border-color: #ff6358;
}
