.hygiene-recare-container {
  display: flex;
  flex-direction: column;
}

.hygiene-recare-form {
  margin: 20px;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
}

.hygiene-recare-form p {
  position: absolute;
  top: 152px;
  color: black;
  background-color: white;
  font-weight: bold;
}

.hygiene-recare-form-input-group {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.hygiene-recare-form-input-group label {
  width: 50%;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.12px;
}

.hygiene-recare-form input[type="number"] {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #444;
  background-color: #2c2c2c;
  color: #fff;
}

.hygiene-recare-save-button {
  padding: 10px;
  background-color: #ff6357 !important;
  color: #fff !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 100px;
  font-size: 16px;
  margin-left: 20px;
  width: 80px;
}

.hygiene-recare-save-button:hover {
  background-color: #218838;
}
