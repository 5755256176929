/* .k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active {
    margin-bottom: -1px;
    border-bottom-width: 1px;
    border-bottom-color: transparent !important;
    background-color: unset;
    border-bottom: 2px solid green;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active  span {
    border-bottom: 2px solid green;
} */

.k-animation-container k-animation-container-relative {
    width: 100%;
}

.k-animation-container {
    width: 100%;
}
