.settings-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.setting-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  gap: 10px;
}

.setting-label {
  font-size: 14px;
}
