.submenu-container {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  z-index: 0;
  background-color: white;
}

.submenu-container .k-button-solid {
  /* margin-right: 20px; */
  background-color: #ff6358 !important;
  color: white !important;
}

.hyperlinks {
  padding-left: 10px;
  display: flex;
  gap: 10px;
}

.hyperlinks a {
  font-weight: bold;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.49px;
  text-align: left;
}

.spacer {
  flex-grow: 1;
}

.dropdown-label {
  margin-right: 10px;
  font-weight: bold;
}

.submenu-dropdown {
  width: 120px !important;
  margin-right: 20px !important;
}

.icon-group {
  display: flex;
  gap: 15px;
}

.submenu-icon {
  font-size: 20px;
  color: white;
  cursor: pointer;
}

.submenu-right {
  display: flex;
  gap: 5px;
  padding-right: 15px;
}

.submenu-left {
  display: flex;
  gap: 5px;
}
