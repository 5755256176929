.content-wrapper {
  display: flex;
}

.office-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings-office-section {
  border: 1px solid #4a4a4a;
  border-radius: 8px;
  margin: 20px;
  position: relative;
  color: black !important;
  padding: 15px;
}

.settings-office-section-title {
  position: absolute;
  top: -12px;
  left: 20px;
  color: black;
  padding: 0 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: white !important;
}

.field-group {
  margin-top: 15px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: space-around;
  align-items: center;
}

.ip-list {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.ip-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.save-button {
  align-self: flex-end;
  margin-top: 20px;
}

.field-group {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.checkbox-group-label {
  align-self: center;
  margin-right: 10px;
  font-weight: bold;
  color: black;
}

.bill-under-checkboxes {
  display: flex;
  align-items: center;
  gap: 50px;
  padding-top: 15px;
}
