.check-in-container {
  overflow-y: auto;
  height: calc(100vh - 110px);
  overflow-x: hidden;
  margin: 10px;
  padding: 15px;
  border-radius: 15px;
}

.check-in-container h3 {
  color: black;
  padding-left: 30px;
  padding-top: 20px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.check-in-container label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: right;
  color: black;
}

.check-in-container p {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  position: absolute;
  top: 144px;
  color: black;
  background: white;
}

.patient-portal-check-in {
  display: flex;
  gap: 2rem;
  color: black;
  padding: 2rem;
  border-radius: 8px;
}

.patient-portal {
  flex: 1;
  padding: 2rem;
  border: 1px solid black;
  border-radius: 8px;
}

.patient-portal-check-in-label-plus-component {
  margin-left: 40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.patient-portal-check-in-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  padding-right: 30px;
  float: right;
}

.patient-portal-check-in-actions button {
  background: #ff6358;
  color: white;
  padding: 8px;
}
