@import "~@progress/kendo-theme-default/dist/all.css";
@import "@progress/kendo-font-icons/dist/index.css";

body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  font-family: "Manrope", sans-serif;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure consistent box-sizing */
}

#root {
  height: 100%; /* Ensures the root element takes up full height */
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px !important; /* Adjust the width as needed */
  height: 8px !important; /* Adjust the height for horizontal scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: white !important; /* Color of the scrollbar thumb */
  border-radius: 10px !important; /* Rounded corners */
}

.k-grid .k-table-tbody,
.k-grid .k-table-tfoot {
  color: black !important;
}

.k-tabstrip-top>.k-tabstrip-items-wrapper .k-item.k-active{
  border: 2px solid black;
  background: whitesmoke !important;
  border-bottom-color: black !important;
}

.k-window-content{
  background: whitesmoke !important;
}

.k-checkbox{
  border-color: black !important;
}

.k-grid .k-table-row {
  /* background-color: #273c45 !important; */
  color: black !important;
}

.k-button-solid-primary{
color: white !important;
}

.k-grid .k-grid-header .k-table-th{
  font-weight: bold !important;
}
