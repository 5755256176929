.teeth-grid {
  display: flex;
  font-family: Arial, sans-serif;
  padding: 10px;
  color: white;
}

h3 {
  text-align: center;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
}
.controls-select {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.select-all-btn {
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.select-all-btn:hover {
  background-color: #45a049;
}

.select-all-btn.selected {
  background-color: #ff5722;
}

.tooth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  position: relative;
}

.tooth.selected {
  background-color: #4caf50; /* Green color for selected teeth */
}

.tooth-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tooth-image {
  width: 45px;
  height: 60px;
  margin: 2px;
}

.tooth-number {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  bottom: -15px;
}

.upper-teeth,
.lower-teeth {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  margin: 10px;
}

.upper-teeth .row {
  margin-top: 10px;
}

.lower-teeth .row {
  margin-top: 20px;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.toolbar .tools button,
.toolbar .color-picker input,
.toolbar .brush-size select {
  margin: 0 5px;
  padding: 10px;
  border-radius: 5px;
}

.toolbar .tools button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.toolbar .tools button:hover {
  background-color: #0056b3;
}

.toolbar {
  color: #ffffff;
}
/* Right-side grid section (Teeth Manipulator Panel) */
.grid-left{
  background-color: #b39f9e !important;
  padding: 5px;
}
.grid-right {
  width: 400px;
  height: 500px;
  margin: 10px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #333;
  font-family: "Arial", sans-serif;
  transition: transform 0.3s ease-in-out;
}
.teeth-views {
  display: flex;
  gap: 35px;
}
/* Heading for the control panel */
.grid-right h3 {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #007bff; /* Accent blue for the heading */
}

/* Selection buttons: Select All, Unselect All */
.selection-toggle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.selection-toggle button {
  background-color: #2ecc71; /* Green color for selection actions */
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  width: 100%;
}

.selection-toggle button:hover {
  background-color: #27ae60; /* Darker green on hover */
  transform: scale(1.05);
}

.selection-toggle button:active {
  transform: scale(0.98); /* Slight shrink on click */
}

/* Clear selection button: Deselect All */
.clear-all {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
}

.clear-all button {
  background-color: #e74c3c; /* Red for clear actions */
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s ease;
  width: 100%;
}

.clear-all button:hover {
  background-color: #c0392b; /* Darker red on hover */
  transform: scale(1.05);
}

.clear-all button:active {
  transform: scale(0.98); /* Slight shrink on click */
}
.healthinfo-box {
  margin: 10px;
  display: flex;
  grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns */
  gap: 12px;
  border: 1px solid #6f828a;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
}
.healthinfo-title {
     position: absolute;
    top: -12px;
    left: 10px;
    font-weight: bold;
    padding: 0 10px;
    font-size: 14px;
    color: black;
    background-color: white !important;
}
/* Responsive Layout */
@media (max-width: 768px) {
  .grid-right {
    width: 100%;
    padding: 15px;
  }

  .toolbar .tools button {
    font-size: 14px;
    padding: 10px;
  }

  .toolbar .brush-size select,
  .toolbar .color-picker input {
    padding: 8px;
    font-size: 12px;
  }

  .selection-toggle button {
    font-size: 12px;
    padding: 10px;
  }

  .clear-all button {
    font-size: 12px;
    padding: 10px;
  }
}
