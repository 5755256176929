.profile-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: black;
  gap: 40px;
}

.profile-left {
  flex: 1;
  padding: 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background: whitesmoke;
  border: 2px solid slategray;
}

.profile-icon {
  text-align: center;
  display: flex;
  justify-content: center;
}

.profile-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: slategray; /* Green background */
  color: white;
  font-size: 40px;
  border-radius: 50%; /* Make it circular */
  font-weight: bold;
}

.profile-info h2,
.profile-info p {
  text-align: center;
}

.family-members {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.family-member-img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.profile-right {
  flex: 2;
  padding: 20px;
  border: 2px solid slategray;
  border-radius: 10px;
  background: whitesmoke;
}

.details {
  display: grid;
  grid-template-columns: repeat(
    3,
    1fr
  ); /* 3 columns for 12 items, 4 in each row */
  gap: 20px;
}

.detail-item h4 {
  margin: 0;
  color: black; /* Lighter color for headings */
}

.detail-item p {
  margin: 0;
  font-size: 14px;
}
