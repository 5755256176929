.login-form {
  width: 600px;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: black;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.form-field {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.password-field {
  position: relative;
}

.password-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  right: 25px;
  cursor: pointer;
  color: black;
}
