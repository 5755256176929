.book-my-visit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.book-my-visit-top-ribbon {
  background: darkolivegreen;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  width: 70%;
}

.doctor-name-plus-button {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.book-my-visit-form-container {
  display: flex;
  margin-top: 20px;
  width: 60%;
  justify-content: space-between;
}

.book-my-visit-form {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 60%;
  flex-direction: column;
}
.book-my-visit-label-plus-comp {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.book-my-visit-buttons {
  display: flex;
  margin-top: 30px;
  width: 60%;
  justify-content: space-around;
}
