.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-buttons {
  display: flex;
  gap: 10px;
}

.status-label {
  margin-right: 10px;
  font-size: 18px;
  color: white;
  padding-top: 6px;
}

.admin-access {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-access .k-link {
  background: rgba(45, 70, 81, 1) !important;
  color: white !important;
}

.schedule-options {
  display: flex;
  background: #1a262f;
  color: white;
  justify-content: space-between;
}

.access-save-button {
  background: rgba(0, 206, 144, 1) !important;
  width: 80px;
  margin-top: 15px;
  color: white !important;
  float: right !important;
}
