.exams-and-hygiene-main-wrapper {
  margin: 30px;
}

.exams-and-hygiene-main-wrapper .k-grid-content {
  background: #1a262f !important;
}

.k-grid .k-table {
  width: 100% !important;
  color: white !important;
}

.k-table-thead {
  color: white !important;
}
