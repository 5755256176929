/* PageLayout.css */
.datatable-layout {
  overflow-y: auto;
  height: calc(99vh - 110px);
  width: calc(100vw - 180px);
  overflow-x: hidden;
  background: white;
  margin: 10px;
  padding: 15px;
  border-radius: 15px;
}

.datatable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datatable-header h1 {
  color: black;
  font-size: 18px;
  font-weight: 400;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.content {
  width: 100%;
  overflow-y: auto;
  color: black;
}

.navbar-template-content {
  width: 100%;
  overflow-y: auto;
  color: black;
  margin: 10px;
}

.save-button {
  float: right;
}
