.visit-reminders-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  color: black;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.visit-reminders-container .first {
  position: absolute;
  top: 169px;
  color: black;
  background: white;
}

.visit-reminders-container .second {
  position: absolute;
  top: 313px;
  background-color: white;
}

.visit-reminders-sub-container {
  flex: 1 1 calc(25% - 20px);
  border: 1px solid #444;
  border-radius: 8px;
  padding: 20px;
}

.visit-reminders-label-plus-content {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}
