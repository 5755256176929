/* Main Scheduler Container */
.k-scheduler {
  /* background: rgba(0, 0, 0, 0.3) !important; */
  color: black !important; /* Light text for better contrast */
  opacity: 0.95 !important;
  border-color: #605b5b !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-style: normal !important;
  line-height: normal !important;
  font-weight: normal !important;
  padding-top: 20px;
  box-shadow: 0px 2px 0px 0px #ff6358 !important;
  border-radius: 20px;
  border: 0px;
}

.k-scheduler-layout-flex .k-scheduler-cell.k-heading-cell {
  font-weight: normal !important;
}
/* Header Toolbar Styles */
.k-scheduler-toolbar {
  /* background-color: #24363e !important; Slightly lighter than the main background */
  border-bottom: 1px solid #3b3f45; /* Subtle border to separate header */
}

.k-scheduler-head {
  background-color: #1b2c33 !important;
  color: black !important; /* Light text for better contrast */
}

.k-scheduler-layout-flex .k-scheduler-cell.k-side-cell {
  color: black !important;
  border-bottom: none !important;
}

.k-event {
  color: black !important;
  /* background-color: wheat !important; */
}
.k-tooltip {
  background-color: #a7b045 !important; /* Change background color to green */
  color: #1a262f; /* Set text color to white */
  border-radius: 8px; /* Add rounded corners */
  font-size: 14px; /* Adjust font size */
  padding: 10px; /* Add padding */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
}
.k-scheduler-toolbar {
  background: whitesmoke !important;
  left: 68px !important;
  border-radius: 10px 10px 0 0 !important;
  width: 95%;
}
.k-scheduler-toolbar .k-button {
  left: 20px !important;
  background-color: white !important; /* Transparent buttons with text */
  color: black;
  box-shadow: 0px 2px 5px 0px #ff6358;
  border-radius: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.k-scheduler .k-button-md.k-icon-button {
  left: 35px !important;
  box-shadow: none !important;
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: black;
}

.k-scheduler-toolbar .k-nav-current {
  left: 50px !important;

  background: transparent !important;
  color: black !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.k-scheduler-views .k-toolbar-button {
  box-shadow: none !important;
  color: black !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  background: transparent !important;
}
.k-scheduler .k-button-solid-base.k-selected {
  box-shadow: 0px 2px 5px 0px #ff6358 !important;
  color: black !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  border-color: transparent !important;
}
.k-scheduler-views {
  right: 100px !important;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.k-scheduler-toolbar .k-button:hover {
  background-color: #3b3f45; /* Hover state for buttons */
}

.k-scheduler-toolbar .k-button.k-state-selected {
  background-color: #00b300; /* Green highlight for selected button */
  color: #fff;
}
.k-scheduler-footer {
  background: whitesmoke !important;
  border-radius: 0 0 10px 10px !important;
}
/* Scheduler Grid (Time slots) */
.k-scheduler .k-scheduler-table td {
  border: 1px solid #3b3f45; /* Lighter border for grid cells */
}

/* Time column styling */
.k-scheduler .k-scheduler-timecolumn,
.k-scheduler .k-scheduler-header td {
  background-color: #23272e; /* Darker time and header background */
  color: #b0b0b0; /* Lighter text color */
}

/* Appointment Block Styling */
.k-event {
  background-color: #8bc34a; /* Green background for events */
  color: #000; /* Black text for contrast */
  border-radius: 5px; /* Rounded corners for event blocks */
  padding: 5px;
}

.k-event:hover {
  background-color: #689f38; /* Darker green on hover */
}

/* Date Picker and Other Input Styles */
.k-picker-wrap {
  background-color: #23272e;
  border: 1px solid #3b3f45;
}

.k-picker-wrap input {
  color: #e0e0e0;
  background-color: transparent;
}

/* Adjust dropdowns for dark theme */
.k-dropdown-wrap,
.k-dropdownlist {
  background-color: #23272e;
  color: #e0e0e0;
  border: 1px solid #3b3f45;
}

.k-dropdown-wrap:hover {
  border-color: #00b300; /* Green border on hover */
}

/* Time slot labels */
.k-scheduler-timecolumn-cell {
  color: #b0b0b0; /* Time labels in lighter grey */
}

/* Selected time slot styling */
.k-scheduler-cell.k-state-selected {
  background-color: #2d3138; /* Dark background for selected time slot */
  border-color: #00b300; /* Green border for selected time */
}

.k-radio input:checked + .k-radio-label {
  color: #00b300; /* Green highlight for selected orientation */
}

/* For medium-sized screens (tablets, small desktops) */
@media screen and (max-width: 1200px) {
  .k-scheduler-toolbar {
    left: 50px !important;
    width: 90%; /* Adjust width to 90% of the screen */
  }
}

/* For smaller screens (tablets, mobile landscape) */
@media screen and (max-width: 992px) {
  .k-scheduler-toolbar {
    left: 30px !important;
    width: 85%; /* Reduce width to fit smaller screens */
  }
}

/* For small mobile devices (portrait mode) */
@media screen and (max-width: 768px) {
  .k-scheduler-toolbar {
    left: 15px !important;
    width: 100%; /* Full width on smaller screens */
  }
}

/* For very small devices (mobile portrait, narrow screens) */
@media screen and (max-width: 480px) {
  .k-scheduler-toolbar {
    left: 10px !important;
    width: 100%; /* Full width for tiny devices */
  }
}

.k-scheduler-layout-flex .k-scheduler-group {
  background-color: whitesmoke;
}
