.dental-perio-chart {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  height: calc(100vh - 110px);
  padding: 10px;
}

.right-section {
  flex: 1;
}

.color-picker-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.color-picker-row label {
  font-size: 14px;
  color:black;
  flex: 1;
}

.datatable-container {
  margin-bottom: 20px;
}

.datatable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.toggle-section {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 20px;
  margin-top: 20px;
  color: black;
}
.custom-form {
  overflow: initial;
}
