.online-reviews-container {
  padding: 20px;
  color: black;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.online-reviews-sub-container {
  display: flex;
  margin-bottom: 30px;
  gap: 150px;
}

.online-reviews-label-plus-component {
  display: flex;
  align-items: center;
  gap: 20px;
}

.social-media-links-label-plus-component {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
  flex: 1 1 40%;
}

.social-media-links-container {
  border-top: 1px solid #ccc;
  padding: 20px;
  border: 1px solid rgba(111, 130, 138, 0.5);
  border-radius: 10px;
}

.social-media-links-container h3 {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  position: absolute;
  background-color: white;
  top: 319px;
}
