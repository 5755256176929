.drawer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background: white;
}

.icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #f5f5f533;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
}

.drawer-icon {
  font-size: 24px;
  cursor: pointer;
  color: slategray;
}

span.selected {
  font-size: 24px;
  cursor: pointer;
  color: #fff;
}
