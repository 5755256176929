.custom-input {
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #D0D0D0; /* Soft Gray */
    background-color: #FFFFFF; /* White Smoke */
    color: #333; /* Charcoal Gray */
    width: 100%;
    box-sizing: border-box;
  }
  
  .custom-input:focus {
    border-color: #B2B5E0; /* Light Steel Blue */
    outline: none;
  }
  