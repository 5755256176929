.ortho-lists {
  padding: 20px;
  color: black;
}

.ortho-lists-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 20px;
  color: black;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-grid {
  padding-top: 10px;
}
