.insurance-title {
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 5px;
}

.insurance-title .k-tabstrip-items-wrapper .k-item.k-active {
  background: transparent !important;
}

.insurance-container {
  color: white;
  padding: 10px;
  border-top: 1px solid;
  display: flex;
}

.insurance-details-container {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 220px;
}

.insurance-key {
  color: black;
}

.insurance-value {
  color: black;
}

/* Plan details header */
.insurance-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: black;
}

/* Flex container for the plan details section */
.plan-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* Individual column styling for plan details */
.plan-column {
  flex: 1;
  padding: 0 20px;
}

.plan-column p {
  margin: 5px 0;
}

/* Chart container styling */
.chart-container {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 60px;
  align-items: center;
}

.chart-container h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.12px;
  text-align: left;
  color: black;
}

/* Chart wrapper */
.chart-wrapper {
  width: 200px;
  height: 200px;
}

.chart-wrapper canvas {
  width: 100%;
  height: 100%;
}

/* Make the layout responsive */
@media (max-width: 900px) {
  .plan-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .chart-container {
    flex-direction: column;
    align-items: center;
  }

  .chart-wrapper {
    margin-bottom: 20px;
  }
}

.tab-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-right-content {
  display: flex;
  align-items: center;
  gap: 50px;
}

.tab-icons {
  color: #ff6358 !important;
  margin-left: 10px;
  gap: 10px;
  display: flex;
}

.tab-icons i {
  margin-left: 8px;
  font-size: 14px;
}

.k-chart {
  fill: none !important;
}
