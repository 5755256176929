.k-grid .k-hierarchy-cell .k-icon {
  color: black !important;
  opacity: 0.5;
}

.k-grid .k-grid-header {
  padding: 0px;
  border-bottom-width: 0px !important;
}

.k-grid-norecords {
  display: none !important;
}
.datatable-layout .k-grid {
  border-radius: 8px; /* Rounded corners */
  overflow: hidden;
  border-top: 1px solid #505152 !important;
  border-left: 1px solid #505152 !important;
  border-right: 1px solid #505152 !important;
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.5);
}

.datatable-layout .k-grid td {
  border-bottom: 1px solid #505152 !important;
}

.datatable-layout .k-table-thead {
  color: black !important;
  background-color: white !important;
  font-weight: bold;
}
.datatable-layout .k-grid-container .k-grid-content {
  background: #1a262f;
  overflow: hidden !important;
}

.datatable-layout .k-grid .k-grid-header .k-table-th .k-link {
  color: black !important;
  font-weight: bold;
}

@media print {
  * {
    visibility: hidden;
  }

  .k-grid {
    visibility: visible;
    padding: 0px;
    margin-top: -60px;
  }

  .k-grid * {
    visibility: visible;
  }
}
