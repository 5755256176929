.notification-container {
  margin: 50px;
  line-height: 3;
  padding: 10px;
}

.settings-notification-label {
  gap: 20px;
  display: flex;
  align-items: center;
}

.notification-visit-status {
  font-family: Manrope;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: left;
  position: absolute;
  top: 157px;
  color: black;
  background-color: white;
}

.notification-container-visit-status {
  margin: 50px;
  line-height: 3;
  border: 1px solid black;
  font-size: 14px;
  padding: 10px;
  border-radius: 10px;
  width: 400px;
}

.notification-container label {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
