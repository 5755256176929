.procedure-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  gap: 10px;
  padding: 10px;
}

.procedure-btn {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.procedure-btn:hover {
  background-color: #4caf50;
}

.procedure-btn.selected {
  background-color: #ff5722;
}

.procedure-icon {
  width: 40px;
  height: 40px;
}

.procedure-label {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
}
