.k-animation-container .k-popup {
  background-color: white;
  border-radius: 0;
  color: black;
}

.k-animation-container .k-list {
  background-color: white;
  color: black;
}
.k-animation-container .k-list-item.k-selected:hover,
.k-animation-container .k-list-item.k-selected {
  background-color: white;
  color: black;
}
.k-animation-container .k-selected.k-list-optionlabel,
.k-animation-container .k-selected.k-list-optionlabel:hover {
  background-color: white;
  color: black;
}

.k-floating-label {
  color: black;
}
