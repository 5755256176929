.popup-content,
.k-window {
  background-color: #2a2f38;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
  max-width: 90%;

  /* Centering the popup horizontally and vertically */
  /* Ensures the popup is on top of other elements */
  background-color: #273c45 !important;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  min-width: 80%;
  border-bottom: 1px solid black !important;
}

.popup-header,
.k-window-titlebar,
.k-window-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  padding: 10px;
  color: black;
}
.dialog-container .k-window-content,
.dialog-container .k-prompt-container,
.k-dialog-content {
  overflow: hidden !important;
}
.popup-close-button {
  background: none;
  border: none;
  color: #e0e0e0;
  font-size: 1.5em;
  cursor: pointer;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.k-tabstrip-content {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: black !important;
  width: 100% !important;
}

.custom-tabs .k-tabstrip-items .k-item {
  background-color: transparent;
  color: #e0e0e0;
  border-bottom: 2px solid #3b3f45;
}

.custom-tabs .k-tabstrip-items .k-item.k-state-active {
  color: #4caf50;
  border-bottom: 2px solid #4caf50;
}

.popup-header .k-button-solid-base {
  background-color: transparent !important;
  color: white !important;
}

.custom-form {
  position: relative;
  overflow-y: auto;
}

.tab-class {
  position: relative;
  overflow-y: auto;
}

.form-tabstrip {
  /* height: 500px; */
}

.form-tabstrip .k-tabstrip-items-wrapper .k-item.k-active {
  background: transparent !important;
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  /* Space between Prev and Next buttons */
  align-items: center;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
}

.prev-button,
.next-button {
  background-color: #ff6358;
  border: none;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.prev-button.hidden {
  visibility: hidden;
  /* Hide Prev button but maintain its space */
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* 3 columns for form groups */
  row-gap: 8px;
  /* Adds space between rows */
}

.form-group {
  display: grid;
  grid-template-columns: 25% 70%;
  /* Label takes 40%, input takes 60% */
  align-items: center;
  /* Aligns label and input vertically */
  column-gap: 10px;
  /* Adds space between the label and the input */
}

.form-group label {
  text-align: right;
  margin-bottom: 0;
  color: black;
  font-size: 14px;
  white-space: normal;
  /* Allow the label to break into new lines if needed */
}

.form-group input,
.form-group select .k-input {
  padding: 8px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: transparent;
  /* color: white; */
  opacity: 0.4;
  /* width: 200%; */
}

.custom-form .k-animation-container-relative {
  display: block !important;
}

.custom-form .section-box {
  border: 1px solid #6f828a;
  /* Border around the section */
  border-radius: 8px;
  /* Slightly rounded corners */
  margin-top: 20px;
  /* Space between sections */
  position: relative;
  /* To allow the title to overlap the box */
}

.custom-form .box {
  margin-top: 20px;
  /* Space between sections */
  position: relative;
  /* To allow the title to overlap the box */
}

.custom-form .section-title {
  position: absolute;
  /* Absolutely position the title */
  top: -12px;
  /* Adjust position so it's on top of the border */
  left: 10px;
  font-weight: bold;
  /* Align it to the left inside the section */
  padding: 0 10px;
  /* Padding around the text */
  font-size: 14px;
  color: black;
  background-color: white !important;
}

.k-item {
  color: black !important;
}

.custom-form .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active,
.custom-form .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-bottom: 0px !important;
  /* Adjust margin to 0 */
  border-bottom-color: black !important;
  /* Custom border color (red in this case) */
  background-color: transparent !important;
}

/* .k-popup {
  background-color: #2a2f38;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #e0e0e0;
  height: auto;
  max-height: 85%;
  max-width: 85%;
  overflow-y: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: tran;
} */

/* Header and Footer Sections */
.custom-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
}

.custom-section-left {
  /* width: 50%; */
  display: flex;
}

.custom-section-right {
  display: flex;
  justify-content: flex-end;
  /* width: 50%; */
}

.header-footer-group {
  margin-right: 10px;
}

.custom-form .k-item.k-active {
  background-color: transparent !important;
}

/* Footer Button Styles */
.footer-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.tab-content {
  width: 100% !important;
  padding: 10px;
  box-sizing: border-box;
  min-height: 550px;
}

.textarea-row {
  grid-column: span 3;
  /* Ensures it spans the entire row */
  display: flex;
  flex-direction: column;
}

.textarea-label {
  color: #e0e0e0;
  font-size: 14px;
}

/* Specific styling for full-width textarea */
.full-width-textarea-group {
  grid-column: 1 / -1;
  /* Span across all columns */
  display: flex;
  align-items: center;
  /* Align label with other fields */
  column-gap: 10px;
  /* Space between label and textarea */
}

.full-width-textarea-group .textarea-label {
  flex: 0.09;
  color: black;
  font-size: 14px;
  margin-right: 0 !important;
}

.full-width-textarea-group .full-width-textarea {
  flex: 1;
  /* Textarea takes up the remaining space */
  padding: 8px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: #273c45;
  color: white;
  opacity: 0.8;
  width: 100%;
  min-height: 50px;
  /* Ensure a minimum height */
}

.popup-content .k-radio-list {
  flex-flow: row !important;
  border-radius: 0px !important;
}

.popup-content .k-radio {
  border-radius: 50% !important;
}

.popup-content .k-radio-list-item {
  margin-right: 5px !important;
}

/* Non-Grid Layout: Automatically adjusts columns based on container width */

.non-grid-layout,
.non-grid-layout .form-group,
.composite-field {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.non-grid-layout {
  flex-wrap: wrap;
}

.non-grid-layout .form-group label {
  font-weight: bold;
}

.non-grid-layout .form-group input,
.non-grid-layout .form-group textarea,
.non-grid-layout .form-group select,
.non-grid-layout .form-group .k-widget {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.numeric-textbox-with-suffix {
  display: flex;
  align-items: center;
}

.numeric-suffix {
  margin-left: 5px;
  font-size: 14px;
}

.password-field-container {
  display: flex;
}

.custom-form .content {
  padding: 10px;
  width: auto;
}

.search-box input {
  border: 0px;
  border-radius: 4px;
  background-color: transparent;
  color: white;
  opacity: 1;
  /* width: 200%; */
}

@media (max-width: 1300px) {
  .section-box {
    width: 100%;
    /* Allow it to take full width on smaller screens */
    max-width: 100%;
    /* Make sure it doesn't overflow */
  }
}

/* Responsive Design */
@media (max-width: 1300px) {
  .popup-content .section-box {
    width: 90%;
    max-width: 90%;
  }

  .form-grid {
    grid-template-columns: repeat(2, 1fr); /* Switch to 2 columns */
    gap: 12px;
  }

  .custom-form .section-box {
    width: 90%;
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .custom-form .popup-content .section-box {
    width: 95%;
    padding: 15px;
  }
  .custom-form .custom-section,
  .custom-section-left,
  .custom-section-right {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* margin-bottom: 10px; */
    row-gap: 10px;
  }

  .custom-form .form-grid {
    grid-template-columns: 1fr; /* Single column */
    gap: 8px;
    display: flex;
    flex-direction: column;
  }

  .full-width-textarea-group .textarea-label {
    flex: 0.2;
  }

  .full-width-textarea-group .full-width-textarea {
    flex: 0.8;
  }
}

@media (max-width: 480px) {
  .custom-form .popup-content {
    width: 100%;
    max-width: 100%;
    padding: 10px;
  }

  .custom-form .form-group {
    grid-template-columns: 30% 65%;
  }

  .full-width-textarea-group {
    flex-direction: column;
  }

  .full-width-textarea-group .textarea-label {
    flex: 0;
    text-align: left;
    margin-bottom: 5px;
  }
}

.section-box {
  margin-bottom: 20px; /* Add spacing between sections */
}

.horizontal {
  display: flex;
  gap: 20px; /* Space between fields */
}

.vertical {
  display: block;
}

.grid {
  display: grid;
  gap: 20px; /* Space between items */
}

.flex-display {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.flex-basis {
  flex: 1 1 200px;
  min-width: 200px;
}

/* Fields inside a grid layout */
.grid-field-wrapper {
  display: flex;
  flex-direction: column; /* Stack label and input vertically */
  gap: 5px; /* Space between label and input */
  width: 100%; /* Ensure full width for inputs */
}

.form-grid {
  align-items: start;
}
