.actions {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.transfer-select {
  margin-right: 15px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #1a1e27;
  color: #ffffff;
}

.for-dental,
.for-hygiene {
  margin-right: 10px;
  background-color: #ff6358 !important;
  color: #ffffff !important;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}
