 .expandable-menu {
  border: none !important;
  background-color: whitesmoke;
  color: black;
  padding: 20px;
  border-radius: 15px;
  border-color: unset;
 }

.expandable-menu-popup {
  border: none !important;
  background: none !important;
}