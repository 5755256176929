.upload-section .k-upload .k-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 600px;
  min-height: 320px;
  text-align: center;
  border-radius: 3px;
  background-color: #1f2f3a;
}
.upload-section .k-upload-empty {
    border-radius: 3px;
  border: 2px dashed #4a4a4a; /* Adjust color as needed */
opacity: 0.4;
}
.upload-section .k-dropzone-hint {
  display: grid;
  align-items: center;
  display: none;
}
.upload-section .k-upload-button {
  display: none; /* Hide the default upload button */
}

.upload-section h3 {
  color: #a0a0a0; /* Adjust color as needed */
  font-size: 1rem;
  margin-bottom: 1rem;
}
