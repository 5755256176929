.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent overflow on the wrapper */
}

.sidebar {
  width: 80px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.settings-goal-main-content {
  flex: 1;
  height: calc(100vh - 110px);
  overflow: hidden;
    margin: 10px;
  padding: 15px;
  border-radius: 15px;
}

.tab-buttons-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.settings-goal-tab-buttons {
  display: flex;
  gap: 10px;
}

.settings-goal-tab-buttons .k-button-solid-base {
  background: rgba(167, 176, 69, 1) !important;
  color: black;
}

.settings-goal-tab-content {
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  border-radius: 10px;
  height: 85%;
  overflow: auto;
}

.settings-goal-main-content .k-tabstrip-content {
  display: none !important;
}

.settings-goal-main-content .k-link {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 19.12px;
  text-align: left;
}

.tab-buttons-container .k-tabstrip-items-wrapper .k-item.k-active {
  border-radius: 15px;
  border: 1px solid;
  background: rgba(0, 206, 144, 1);
}

.settings-goal-main-content
  .k-tabstrip-top
  > .k-tabstrip-items-wrapper
  .k-item.k-active
  span {
  border-bottom: none;
}

.settings-goal-dropdown-container {
  display: flex;
  align-items: center;
  color: white;
}

.settings-goal-dropdown-label {
  margin-right: 10px;
  font-weight: bold;
}

.settings-goal-clinician-dropdown {
  width: 140px;
}
