.label-plus-dropdown-audit {
  margin-right: 16px;
  color: black;
  display: flex;
  align-items: center;
}

.audit-log-criteria {
  display: flex !important;
  align-items: center !important;
  padding: 10px !important;
  border-radius: 8px !important;
  border: 1px solid black !important;
  margin-bottom: 20px !important;
  gap: 4px !important;
}

.label-plus-dropdown-audit {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
  color: black !important;
  font-family: Manrope !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 19.12px !important;
  text-align: right !important;
}
