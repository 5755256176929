.patient-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: whitesmoke;
  color: black;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap; /* Allows wrapping for responsiveness */
}

.patient-info-section {
  display: flex;
  align-items: center;
  min-width: 200px; /* Ensures patient info does not shrink too much */
}

.patient-info {
  display: flex;
  align-items: center;
}

.patient-image-wrapper {
  position: relative;
  width: 80px; /* Adjust based on the image size */
  height: 80px; /* Adjust based on the image size */
  margin-right: 20px;
}

.patient-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image maintains aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: slategray;
  color: white;
  font-size: 40px;
  border-radius: 50%;
  font-weight: bold;
}

.family-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image maintains aspect ratio */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: slategray;
  color: white;
  font-size: 25px;
  border-radius: 50%;
  font-weight: bold;
}

.family-image-wrapper {
  position: relative;
  width: 50px; /* Adjust based on the image size */
  height: 50px; /* Adjust based on the image size */
  margin-right: 20px;
  margin-bottom: 10px;
}

.g-indicator {
  position: absolute;
  top: -5px; /* Adjust for precise placement */
  right: -5px; /* Adjust for precise placement */
  background-color: #3ac3a3;
  color: black;
  font-weight: bold;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 3px;
}

.patient-name-id {
  display: flex;
  flex-direction: column;
}

.patient-name-id h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.patient-name-id p {
  margin: 5px 0;
  font-size: 14px;
}

.recare-visit {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: black;
}

.recare-visit-icon {
  margin-left: 10px;
}

.patient-details-section {
  flex: 2;
  display: flex;
  flex-direction: row;
  width: 100%; /* Take full width for wrapping */
}

.detail-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px; /* Adds space between items */
  flex: 1;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-item .label {
  flex: 1; /* Ensures the label takes up enough space */
  font-size: 14px;
  color: black;
}

.detail-item p {
  display: flex;
  align-items: center; /* Aligns text and icons vertically */
  margin: 0; /* Removes extra margin for proper alignment */
}

.detail-item .icon {
  color: #6d6d6d; /* Matches the icon color from your screenshot */
  margin-left: 8px; /* Spacing between the icon and text */
}

.detail-item .icon:hover {
  color: #3ac3a3; /* Adds hover effect for icons */
}

.label {
  font-weight: bold;
  font-size: 12px;
  color: #a3a3a3;
  margin-bottom: 5px;
}

.section-divider {
  border: 1px solid black;
  margin: 10px;
}

.family-carousel-section {
  flex: 1;
}

.patient-carousel {
  position: relative;
  max-width: 400px; /* Adjust width for the carousel */
  margin: auto;
  overflow: hidden;
}

.carousel-container {
  position: relative;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.family-member-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #3ac3a3;
  margin-bottom: 5px;
}

.family-member-name {
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin: 0px;
}

.family-member-details {
  font-size: 12px;
  color: black;
  margin: 0px;
}

/* Custom Arrows */
.custom-arrow {
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.custom-left-arrow {
  left: 0px;
}

.custom-right-arrow {
  right: 0px;
}

/* Arrow Shape */
.arrow-shape {
  width: 12px;
  height: 8px;
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  background-color: #6d6d6d; /* Matches the arrow color in the screenshot */
}

.custom-right-arrow .arrow-shape {
  clip-path: polygon(0 0, 100% 50%, 0 100%); /* Flips the arrow for right */
}

.custom-arrow:hover .arrow-shape {
  background-color: #3ac3a3; /* Highlighted arrow color */
}

/* Responsive Design */
@media (max-width: 768px) {
  .patient-card {
    flex-direction: column;
  }

  .patient-info-section {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .patient-details-section {
    flex-direction: column;
    gap: 20px;
  }

  .detail-section {
    grid-template-columns: repeat(1, 1fr); /* Single column on smaller screens */
    min-width: 100%; /* Ensures it spans full width */
  }

  .family-carousel-section {
    margin-top: 20px;
    max-width: 100%; /* Allows full-width carousel on smaller screens */
  }
}
