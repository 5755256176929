.payments-and-claims-container {
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  margin: 10px;
  font-size: 14px;
}

.payments-and-claims-sub-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.payments-and-claims-label-plus-component {
  display: flex;
  align-items: center;
}

.payments-and-claims-container h4 {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  background: white;
  text-align: left;
  position: absolute;
  top: 145px;
}
