.time-punches {
  padding: 20px;
  border: 1px solid rgba(219, 230, 236, 0.2);
  border-radius: 8px;
}

.time-punches-label {
  display: inline-block;
  width: 120px; /* Adjust width to align labels */
  text-align: right;
  padding-right: 10px;
}

.overtime-group,
.custom-dates-group {
  display: flex;
  gap: 10px;
}

.time-punches-grid-layout {
  display: flex;
  margin: 10px;
}
