.plan-and-fees-container {
  color: white;
  padding: 20px;
  max-width: 600px;
}

.plan-and-fees-sub-container {
  display: flex;
  margin-bottom: 15px;
  gap: 20px;
}
