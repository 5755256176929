.settings-adjustment-actions-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.settings-adjustments-action {
  display: flex;
  align-items: center;
}
