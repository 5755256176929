.custom-panel {
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
}

.custom-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}

.custom-panel-title {
  margin: 0;
  color: black;
  font-size: 14px;
  font-weight: 500;
  flex-grow: 1;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-dropdown {
  width: 150px;
}

.add-button:hover {
  background-color: #2a8d75;
}

.toggle-arrow {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
}

.arrow-icon {
  width: 30px;
  height: 20px;
  transform: rotate(0);
  transition: transform 0.3s ease;
  /* opacity: 0.5; */
  color: white;
}

.toggle-arrow.expanded .arrow-icon {
  transform: rotate(180deg); /* Rotate 180 degrees when expanded */
}

.custom-panel-content {
  background-color: #1e2c32;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1200px) {
  .custom-panel {
    padding: 10px; /* Adjust padding for smaller widths */
    max-width: 90%; /* Ensure it adapts to the screen width */
  }

  .custom-panel-header {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .header-actions {
    width: 100%; /* Take full width */
    justify-content: space-between;
  }

  .status-dropdown {
    width: 150px;
    margin-bottom: 10px; /* Add spacing */
  }

  .add-button {
    width: 100%; /* Take full width for buttons */
    justify-content: center;
  }

  .arrow-icon {
    width: 25px; /* Reduce icon size for smaller screens */
    height: 18px;
  }
}

@media screen and (max-width: 768px) {
  .custom-panel-header {
    flex-direction: column; /* Stack the title and actions vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .header-actions {
    gap: 10px; /* Maintain spacing between dropdown and button */
    flex-direction: column; /* Stack items vertically */
  }

  .status-dropdown,
  .add-button {
    width: 100%; /* Full width for small screens */
  }

  .arrow-icon {
    width: 20px;
    height: 16px;
  }
}

@media screen and (max-width: 480px) {
  .custom-panel-title {
    font-size: 12px; /* Reduce font size for very small screens */
  }

  .header-actions {
    flex-direction: column; /* Stack dropdown and button */
    gap: 5px; /* Reduce spacing */
  }

  .status-dropdown,
  .add-button {
    width: 100%; /* Take full width */
  }
}
