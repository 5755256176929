.menu-container {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid slategray;
  background: white;
}

.menu-left,
.button-group,
.menu-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-plus-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.search-and-grid {
  width: 800px;
  position: absolute;
  top: 50px;
  z-index: 10000;
  border: 1px solid;
  border-radius: 10px;
  background: #273c45;
}

.header-logo {
  height: 40px;
}

.header-dropdown {
  margin-right: 20px;
  background: white !important;
  color: black !important;
}

.header-search {
  margin-right: 40px;
}

.button-group .header-button {
  margin-right: 15px;
  background-color: transparent !important;
}

.icon-group {
  display: flex;
}

.icon-group .header-icon {
  margin-right: 20px;
  font-size: 24px;
  cursor: pointer;
  color:black;
}

.user-info {
  display: flex;
  align-items: center;
  margin-left: 20px;
  height: 40px;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-dropdown img {
  height: 40px;
  width: 40px;
  border-radius: 50%; /* This makes it circular */
  object-fit: cover; /* Ensures the image is cropped if necessary */
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-box {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 300px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 9000;
}

.profile-header {
  display: flex;
  align-items: center;
  background: #1f2f3a;
  padding: 20px;
}

.profile-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-details {
  display: flex;
  flex-direction: column;
  color: #fff;
}

.profile-details span {
  margin: 3px 0;
}

.profile-menu {
  list-style: none;
  padding: 20px;
  margin: 0;
  background: #273c45;
}

.profile-menu li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
}

.profile-icon {
  margin-right: 10px;
}

.checkbox-group {
  display: flex;
  gap: 20px;
  color: black;
}

.actions-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  background: white;
    border-top: 1px solid slategray;
}

.custom-grid .k-grid .k-table-row {
  /* background-color: #273c45 !important; */
  color: black !important;
}

.custom-grid .k-grid-header {
  border-style: none;
}

.k-grid {
  border-width: 0px !important;
  border-style: none !important;
  border-color: transparent !important;
}
.k-grid .k-grid-container .k-grid-content {
  background: white !important;
  color: slategray !important;
  overflow: hidden !important;
  border: 1px solid;
}
.k-table-thead {
  background-color: #2d4651 !important;
}

.k-grid {
  background: none !important;
}

.k-grid-aria-root {
  border: 1px solid;
  /* border-radius: 15px; */
}

.custom-grid .k-icon {
  color: black !important;
}

.buttons-container-button.selected {
  background-color: transparent !important; /* More intense Pastel Purple */
  border-bottom-color: black!important; /* Custom border color (red in this case) */
  border-width: 2px;
  color: black !important; /* Custom border color (red in this case) */
}

.k-picker-solid,
.k-input-solid {
  background-color: transparent !important; /* More intense Pastel Purple */
  border-color: #605b5b !important;
  color: black !important;
  width: 110%;
}

.menu-container .k-picker-solid,
.menu-container .k-input-solid::placeholder {
  opacity: 1 !important;
  color: black !important;
}

.k-grid .k-table-thead{
  background: white !important;
}