.credit-card-and-pay-conainer {
  display: flex;
  gap: 150px;
  color: white;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  margin-top: 15px;
}

.credit-card-and-pay-left-column {
  flex: 1;
}

.credit-card-and-pay-right-column-label-plus-component {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.credit-card-and-pay-right-column {
  flex: 1;
  padding: 20px;
  border: 1px solid rgba(111, 130, 138, 0.5);
  border-radius: 8px;
  margin: 10px;
}

.credit-card-and-pay-right-column h4 {
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  position: absolute;
  top: 131px;
}
