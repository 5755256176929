.automated-text-container {
  padding: 20px;
  border-radius: 10px;
  color: black;
  font-family: Manrope;
}

.automated-text-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
}

.automated-text-label-plus-dropdown {
  display: flex;
  align-items: center;
}

.language-section {
  margin-top: 40px;
  padding: 30px;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
}

.language-section h4 {
  position: absolute;
}

.add-template {
  color: black !important;
  background-color: #ff6358 !important;
  margin-bottom: 10px;
  float: right;
}

.icons-container {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  background-color: lightcoral;
}

.icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition:
    transform 0.2s,
    opacity 0.2s;
}

.icon:hover {
  transform: scale(1.2);
  opacity: 0.8;
}
