.collections-billing-container {
  width: 500px;
  border-radius: 8px;
}

.collections-billing-container h2 {
  margin-bottom: 15px;
  font-size: 16px;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
}

.collections-billing-form {
  margin: 20px;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
}

.collections-billing-form p {
  position: absolute;
  top: 152px;
  color: black;
  background-color: white;
  font-weight: bold;
}

.collections-billing-input-group {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.collections-billing-input-group label {
  width: 50%;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.12px;
}

.collections-billing-save-button {
  padding: 10px;
  background-color: #ff6358 !important;
  color: #fff !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 100px;
  font-size: 16px;
  margin-left: 20px;
  width: 80px;
}

.collections-billing-save-button:hover {
  background-color: #218838;
}
