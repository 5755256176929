.button-group .custom-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 400px;
    background-color: transparent; /* Pastel Purple */
    color: black; /* Charcoal Gray */
    border-top: none;
    border-left: none;
    border-right: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border-radius: 0px !important;
  }

/*
  .custom-button:hover {
    background-color: transparent !important;
    transform: translateY(-2px);
  } 
*/

  .custom-button:active {
    background-color: #ff6358 !important; /* More intense Pastel Purple */
  }
  
  .button-group .k-button-solid-base.k-selected{
    background-color: transparent !important; /* More intense Pastel Purple */
    border-bottom-color: #ff6358 !important; /* Custom border color (red in this case) */
    border-width: 2px;

  }