.insurance-verification-container {
  padding: 20px;
  border: 1px solid rgba(111, 130, 138, 0.5);
  border-radius: 5px;
  margin: 10px;
  font-size: 14px;
}

.insurance-verification-sub-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.insurance-verification-container h4 {
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  position: absolute;
  top: 131px;
  background-color: white;
}
