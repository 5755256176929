.main-content {
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 90px);
  overflow-x: hidden;
  margin: 10px;
  background: white;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid none;
}

.scheduler-homepage {
  display: flex;
  flex-direction: column;
  height: auto;
  background: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent overflow on the wrapper */
}

.sidebar {
  width: 80px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.header {
  height: 60px;
}

.sub-header {
  height: 40px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0;
}
