/* Header Styling */
.document-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.header-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.8;
}

.search-bar {
  padding: 12px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: white;
  width: 25%;
  margin-right: 120px;
  background: rgba(0, 0, 0, 0.3);
}

.header-buttons {
  display: flex;
}

.header-btn {
  color: black;
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: rgba(167, 176, 69, 1);
  font-size: 18px;
}

.header-btn:hover {
  background: rgba(167, 176, 69, 1);
}

/* Body Section */
.body-section {
  display: flex;
  height: 90%;
  background: rgba(0, 0, 0, 0.3);
  padding-top: 10px;
}

/* Sidebar Styling */
.document-sidebar {
  width: 20%;
  background: rgba(36, 54, 62, 1);
  color: #a4aab3;
  padding: 15px;
  margin: 15px;
  overflow-y: auto;
  border-radius: 10px;
}

.document-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.document-sidebar li {
  padding: 10px 0;
  gap: 30px;
  cursor: pointer;
  display: flex;
  align-items: center; /* Align icon and text */
  color: white;
  font-size: 14px;
}

.document-sidebar li:hover {
  background-color: #353b44;
  color: white;
}

.main-documents {
  width: 80%;
  grid-template-columns: repeat(5, 1fr);
  padding: 20px;
  background-color: #1c1f27;
  align-content: flex-start;
}

.document-item {
  background-color: #2a2f3b;
  border-radius: 8px;
  padding: 8px;
  color: white;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 160px;
  height: 200px;
  opacity: 0.2px;
  margin: 5px;
}

.document-item:hover {
  background-color: #3c4151;
}

.document-image {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 8px;
}

.document-details,
.document-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.document-name {
  font-size: 14px;
  font-weight: 500;
}

.menu-icon {
  cursor: pointer;
  color: #fff;
}

.document-date {
  font-size: 12px;
  color: #b0b0b0;
}

.dropzone-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dropzone-content {
  background: white;
  border: 2px dashed rgba(167, 176, 69, 1);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  height: 200px;

}


.gallery-item.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 130px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.card-content {
  text-align: center;
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1rem;
}
.body-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.dropzone-and-gallery {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dropzone-container {
  margin-bottom: 20px;
}

.gallery-container {
  flex: 1;
}
