.interval-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  
  .interval-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .interval-item span {
    font-size: 14px;
    white-space: nowrap; /* Ensures labels stay on one line */
  }
  