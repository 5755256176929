.on-demand-container {
  display: flex;
  padding: 20px;
  color: black;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.12px;
  gap: 40px;
}

.on-demand-column {
  flex: 1;
}

.on-demand-sub-container {
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  border: 1px solid #4a4a4a;
  margin-bottom: 20px;
}

.on-demand-sub-container h4 {
  position: absolute;
}

.on-demand-label-plus-component {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.on-demand-status-plus-button {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.add-template-on-demand {
  color: black !important;
  background-color: #ff6358 !important;
}
