.preventative {
  display: flex;
  column-gap: 10px;
}
.preventative .section-box {
  width: 100%;
  height: 250px;
}
.preventative .form-grid,
.coverage .form-grid {
  display: flex;
  flex-direction: column;
}

.preventative .form-group,
.coverage .form-group {
  grid-template-columns: 50% 70%;
}
.preventative .custom-section-layout {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
.preventative .custom-section-layout .form-group {
  display: flex;
  flex-direction: column;
}
.preventative .custom-section-layout .composite-field {
  flex-direction: column;
}
.coverage {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.first,
.second {
  flex: 0 0 15%; /* Allows each section to take about half of the available space */
  width: auto;
}

.third {
  flex: 1 1 100%; /* Forces the section to take up the full width of the container */
}
.upload-insurance-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.upload {
  display: flex;
  gap: 10px;
}
.upload-section {
  width: 100%;
  height: 100%;
  /* max-width: 400px; */
  margin-bottom: 20px;
}

.upload-section h4 {
  margin-bottom: 10px;
  font-size: 16px;
  text-align: center;
}


.upload-insurance-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
}


.k-upload-button {
  display: none; /* Hide default Kendo upload button */
}

.k-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #a0a0a0; /* Adjust color as needed */
  font-size: 1rem;
}

.upload-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;
}
.custom-placeholder{
      position: relative;
    bottom: 200px;
    text-align: center;
    color: #fff;
    opacity: 0.2;
}

.custom-placeholder .k-icon-button.icon-button{
  color: #fff;
}


