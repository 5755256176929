.alert .k-dialog {
  width: 550px;
}
/* AlertPopup.css */

.alert .form-grid {
  display: flex;
  flex-direction: column;
}

.alert .full-width-textarea-group .full-width-textarea {
  padding: 0px;
}
.alert .full-width-textarea-group {
  display: grid;
}

.alertList .k-checkbox-label {
  color: white;
  opacity: 0.4;
}

.alert .numeric .numeric-textbox {
  width: 100% !important;
}
